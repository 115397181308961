import React from 'react';
import PropTypes from 'prop-types';

import LeftArrow from '../assets/images/left.svg';
import RightArrow from '../assets/images/right.svg';

export const NextArrow = ({ className, onClick }) => (
  <div
    className={className}
    onClick={onClick}
  >
    <img src={RightArrow} alt="arrow_left" title="arrow_left" width="24" height="24" />
  </div>
);

NextArrow.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
};

NextArrow.defaultProps = {
  className: '',
  onClick: () => {},
};

export const PrevArrow = ({ className, onClick }) => (
  <div
    className={className}
    onClick={onClick}
  >
    <img src={LeftArrow} alt="arrow_left" title="arrow_left" width="24" height="24" />
  </div>
);

PrevArrow.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
};

PrevArrow.defaultProps = {
  className: '',
  onClick: () => {},
};

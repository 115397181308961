import React from 'react';

/* eslint-disable */
import QuoteImage from '-!svg-react-loader?name=Quote!../assets/images/homePage/testimonial.svg';
/* eslint-enable */

const Testimonial = (props) => {
  const { quote, imgSrc, author, position } = props;
  return (
    <div className="testimonial">
      <QuoteImage className="testimonials-slideshow__quote" />
      <p className="testimonial--quote">{quote}</p>
      <img className="testimonial--image" src={imgSrc} alt={author} title={author} width="48" height="48" />
      <div className="testimonial--author">{author}</div>
      <div className="testimonial--position">{position}</div>
    </div>
  );
};

export default Testimonial;
